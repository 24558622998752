import React, { useEffect, useMemo, useCallback, useState } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions } from "features/users/redux";

import Input from "components/Input";

import PopUp from "./PopUp";

import "./UserEdit.scss";

const b = block("user-edit");

const UserEdit = ({ match }) => {
  const dispatch = useDispatch();

  const [popUpIsOpen, setPopUpOpened] = useState(false);
  const [amount, changeAmount] = useState("");

  const id = match.params.id;
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const actionProcessing = useSelector(
    state => state.users.actionProcessing,
    shallowEqual
  );
  const usersList = useSelector(
    state => state.users.usersList.users,
    shallowEqual
  );
  const newPassword = useSelector(
    state => state.users.newPassword,
    shallowEqual
  );
  const history = useHistory();

  const user = useMemo(() => usersList?.find(t => +t.id === +id) || {}, [
    id,
    usersList
  ]);

  useEffect(() => {
    if (!usersList) {
      dispatch(actions.getUsersList());
    }
  }, [dispatch, usersList]);

  const onTopUpClick = useCallback(() => {
    if (!actionProcessing) {
      dispatch(actions.changeUserBalance(id, amount, false, changeAmount));
    }
  }, [actionProcessing, dispatch, amount, id]);

  const onWithdrawalClick = useCallback(() => {
    if (!actionProcessing) {
      dispatch(actions.changeUserBalance(id, amount, true, changeAmount));
    }
  }, [actionProcessing, dispatch, amount, id]);

  const onBanClick = useCallback(
    e => {
      const value = +e.target.getAttribute("data-key") ? true : false;
      if (!actionProcessing) dispatch(actions.changeBanState(id, value));
      history.push("/users-list");
    },
    [actionProcessing, dispatch, history, id]
  );

  const onResetClick = useCallback(() => {
    if (!actionProcessing) dispatch(actions.resetPassword(id, setPopUpOpened));
  }, [actionProcessing, dispatch, id]);

  const paymentButtons = useMemo(() =>
    [1, 10, 50, 100, 500, 1000, 5000, 10000].map((item, index) => (
      <div onClick={() => changeAmount(+amount + item)} className={b("payment-button-value")} key={index}>
        {item}
      </div>
    )), [amount]
  )

  return (
    <React.Fragment>
      {popUpIsOpen && (
        <PopUp
          password={newPassword}
          id={id}
          locale={locale}
          onClose={setPopUpOpened}
        />
      )}
      <div className={b()}>
        <div className={b("info")}>
          <div className={b("info-item")}>
            <div className={b("info-title")}>{locale.id}</div>
            <div className={b("value")}>{user.id}</div>
          </div>
          <div className={b("info-item")}>
            <div className={b("info-title")}>{locale.nickname}</div>
            <div className={b("value")}>{user.nickname}</div>
          </div>
          <div className={b("info-item")}>
            <div className={b("info-title")}>{locale.balance}</div>
            <div className={b("value")}>{user.balance}</div>
          </div>
        </div>
        <div className={b("payment-block")}>
          <div className={b("payment-item")}>
            <div className={b("info-title")}>{locale.amount}</div>
            <Input
              type="number"
              value={amount}
              callBack={e => changeAmount(e.currentTarget.value)}
            />
          </div>
          <div className={b("payment-buttons")}>
            {paymentButtons}
          </div>
          <div className={b("buttons-bottom")}>
            <div className={b("unban-button")} onClick={onTopUpClick}>
              {locale.topUp}
            </div>
            <div className={b("ban-button")} onClick={onWithdrawalClick}>
              {locale.withdrawal}
            </div>
          </div>
        </div>
        <div className={b("buttons")}>
          <div className={b("reset-button")} onClick={onResetClick}>
            {locale.resetPassword}
          </div>
          <div className={b("buttons-bottom")}>
            <div className={b("ban-button")} data-key={1} onClick={onBanClick}>
              {locale.ban}
            </div>
            <div
              className={b("unban-button")}
              data-key={0}
              onClick={onBanClick}
            >
              {locale.unban}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserEdit;
