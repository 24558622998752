import { errorStatusMessage } from "./errorStatusMessage";

export const locale = {
  ...errorStatusMessage,
  changeLang: "Changer langue",
  changePas: "Changer pasword",
  logout: "Deconnecter",
  other: "Other",

  newPlayer: "Nouveau client",
  userName: "Nom de utilisateur",
  password: "Mot de passe",
  createAccount: "Creer un compte",
  "/user-create": "Cash register -",
  "/total": {
    0: "Cash register - User transactions",
    1: "Cash register - Cashier transactions",
    2: "Cash register - Admin transactions",
    3: "Cash register - Super Admin transactions",
    4: "Cash register - Limited Owner transactions",
    5: "Cash register - Owner transactions",
    7: "Cash register - Super Agent transactions"
  },
  "/": "Menu",
  "/locale": "Languagies",
  "/change-pas": "Changer pasword",
  "/user-edit": "Cash register - User edit",
  "/users-list": "cash register - balance des clients",
  userCardBalances: "Balances des clients",
  userCard: "clients",
  accountBalance: "balance de compte",
  nickname: "Nom de utilisateur",
  id: "Identifiant",
  balance: "Balance",
  ban: "Bloquer",
  banSuccess: "Bloquer success",
  unbanSuccess: "Debloquer success",
  unban: "Debloquer",
  resetPassword: "Réinitialiser",
  resetSuccess: "Mot de passe réinitialisee",
  networkError: "Erreur de reseau",
  createSuccess: "Succès de creation",
  topUp: "Depot",
  withdrawal: "Retrait",
  topUpSuccess: "Succès  de depot",
  withdrawalSuccess: "Succès  de retrait",
  topUpWithdrawal: "Depot/Retrait",
  ok: "Ok",
  theNewPassword: "nouveau mot de passe ",
  for: "pour",

  "/transaction-history": "clients - historique de transactions",
  startDate: "date de debut",
  endingDate: "fin",
  display: "representer",
  email: "E-mail",
  date: "Date",
  amount: "montant",

  languageSelection: "selectionner langues",
  login: "Me Connecter",

  cashRegister: "Cash register",
  partners: "clients",
  createUser: {
    1: "New user",
    2: "New cashier",
    3: "New admin",
    7: "New admin"
  },
  balances: {
    1: "User cards balances",
    2: "Shop cards balances",
    3: "Admins cards balances",
    7: "Admins cards balances"
  },
  cashiersTransactions: {
    1: "Cashier transactions",
    2: "Shop transactions",
    3: "Admins transactions",
    7: "Admins transactions"
  },
  transactionHistory: "Transaction history",
  revenuBalances: "Revenu balances",

  total: "Totale",
  term: "terme",
  cashPayment: "payement en espece",
  cashPayout: "deboursement en espece",
  withdraw: "Retrait",
  deposit: "Depot",
  search: "Search",
  errorPassword:
    "Le mot de passe doit comprendre: au moins 8 caractères, au moins un chiffre, une majuscule et une minuscule!",
  noPass: "Le mot de passe ne doit pas être vide!",
  currency: "Devise"
};
