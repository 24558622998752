import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions as authActions } from 'features/Auth';

// import settings from './img/settings.svg';
import logoutSVG from './img/logout.svg';
import passSVG from './img/change-pass.svg';
import langSVG from './img/change-lang.svg';

import './Footer.scss';

const Footer = ({ locale, lang, changeLang, logOut, isAuth }) => {
  const b = block('footer');
  const dispatch = useDispatch();

  return (
    <footer className={b()}>
      <div className={b('title')}>{locale.other}</div>
      <Link to='/locale' className={b('item')}>
        <SVGInline svg={langSVG} className={b('image').toString()} />
        {locale.changeLang}
      </Link>
      {isAuth && <Link to='/change-pas' className={b('item')}>
        <SVGInline svg={passSVG} className={b('image').toString()} />
        {locale.changePas}
      </Link>}
      {isAuth && <div className={b('item')} onClick={() => dispatch(authActions.logOut())}>
        <SVGInline svg={logoutSVG} className={b('image').toString()} />
        {locale.logout}
      </div>}
    </footer>
  );
}

export default Footer;