import React, { useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInlive from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';

import './Select.scss';

const b = block('select');

const Select = ({ items, activeItem, onChange, placeholder = '' }) => {
  const [isOpen, changeOpen] = useState(false);

  const itemsList = items.map((item, index) => (
    <li key={`${index}_${item}`} className={b('item')} onClick={() => onChange(item)}>
      {item}
    </li>
  ));

  return (
    <div
      className={b()}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}>
      <div className={b('item', { current: true })}>
        {activeItem || placeholder}
        <SVGInlive className={b('arrow').toString()} svg={ArrowSVG} />
      </div>
      {isOpen && <ul className={b('items-list')}>{itemsList}</ul>}
    </div>
  );
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.object.isRequired,
  placeholder: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Select;
